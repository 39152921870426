<template>
  <aside id="user-details-modal" class="modal-window">
    <header>
      <h2>User Details</h2>
      <a href="#" @click.prevent="$emit('close')" class="close"><fa-icon icon="times" /></a>
    </header>

    <footer>
      <div class="controls right">
        <el-button type="success" size="small" round @click="save" :disabled="!canSave" :loading="loading">Save</el-button>
      </div>
    </footer>

    <div class="scroll-view" v-if="user !== null">
      <div class="name-wrap">
        <profile-picture :user="user" height="80" />
        <name :user="user" full />
      </div>

      <div class="modal-form">

        <div class="form-row">
          <div class="label">
            <label>Admin</label>
            <p>Set if this user has administrator privileges.</p>
          </div>

          <div class="field">
            <el-switch v-model="user.is_admin" :disabled="user.is_owner" />
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Birthday</label>
            <p>Set the month and day of this user's birthday.</p>
          </div>

          <div class="field">
            <div class="date-wrap">
              <el-select v-model="user.birthday_month" placeholder="Month...">
                <el-option v-for="(month, index) in monthOptions" :key="`month_${index}`" :value="(index+1)" :label="month" />
              </el-select>

              <el-select v-model="user.birthday_date" placeholder="Day...">
                <el-option v-for="day in dayOptions" :key="`day_${day}`" :value="day" :label="day" />
              </el-select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Charity</label>
            <p>The charity that receives a donation on this user's birthday.</p>
          </div>

          <div class="field">
            <template v-if="_.isEmpty(user.charity_details) || chooseCharity === true">
              This user hasn't chosen a non-profit yet.
            </template>
            <template v-else>
              <div class="charity-details">
                <strong>{{user.charity_details.name}} <small>({{$formatEin(user.charity_details.ein)}})</small></strong>
                <span>{{user.charity_details.city}}, {{user.charity_details.state}}</span>

                <!--<a href="#" @click.prevent="chooseCharity=true">(change)</a>-->
              </div>
            </template>
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Group</label>
            <p>The group this user celebrates their birthday in.</p>
          </div>

          <div class="field">
            <div class="group-wrap" v-if="groups !== null && channels !== null">
              <el-select v-model="user.group_id" placeholder="Group...">
                <el-option label="No group" :value="null" />
                <el-option v-for="group in groups" :key="`group_${group.id}`" :value="group.id" :label="group.name" />
              </el-select>

              <el-button circle icon="el-icon-refresh" size="mini" :loading="channelsLoading" @click="populateChannels"></el-button>
            </div>
            <p class="hint-text"><strong>Not seeing your group?</strong> Make sure this user has been invited to the group's channel on Slack.</p>
          </div>
        </div>
        

      </div>
    </div>
  </aside>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  computed: {
    canSave() {
      return true;
    },
    groups() {
      return _.filter( this.$store.state.groups, (g) => _.findIndex(this.channels, {id: g.platform_channel_id}) > -1 )
    },
    monthOptions() {
      return _.map(
        _.range(1, 13),
        (m) => {
          return DateTime.fromObject({month: m}).toFormat('MMMM')
        }
      )
    },
    dayOptions() {
      const {daysInMonth} = DateTime.fromObject( {month: this.user.birthday_month, year: 2019} );

      return _.range(1, daysInMonth + 1);
    }
  },
  data() {
    return {
      loading: false,
      channels: null,
      user: null,
      chooseCharity: false,
      channelsLoading: false
    }
  },
  mounted() {
    if( !!this.$route.params.data ) {
      this.$store.dispatch('populateGroups')
      this.user = _.cloneDeep(this.$route.params.data);
      this.populateChannels()
    }
  },
  methods: {
    async populateChannels() {
      this.channelsLoading = true;
      const resp = await this.$api.Slack.get_channels(this.user.id);
      this.channelsLoading = false;
      this.channels = resp;
    },
    async save() {
      this.loading = true;
      await this.$api.Users.update( this.user.id, this.user );
      this.loading = false;

      this.$router.replace({
        name: 'admin-users',
        params: {
          forceRefresh: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.name-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
  ::v-deep .cupcake-name {
    font-size: 22px;
    margin: 0 0 0 15px;
  }
}

.date-wrap {
  display: flex;
  align-items: center;
  margin: 0 -10px;

  ::v-deep .el-select {
    margin: 0 10px 0 0;
    flex: 1;
  }
}

.group-wrap {
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  ::v-deep .el-select {
    margin: 0 10px 0 0;
    flex: 1;
  }

  button {
    flex: 0 0 28px;
  }
}

.charity-details {
  strong {
    display: block;
    > small {
      font-size: 0.8em;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  span {
    color: #636363;
    display: block;
  }

  a {
    display: inline-block;
    margin: 5px 0 0;
    font-size: 12px;
  }
}
</style>